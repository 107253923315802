<template>
  <el-main class="simple6-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        健走點燈摘星
      </div>
      <p>2023年2月 </p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">線上健行｜探索台灣新路線</p>
        <p class="image_desc">圖片為看健臺灣探索制霸368鄉鎮主視覺</p>
        <p class="image_desc">(圖片提供：永悅健康)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">公益目標</div>
        <div class="content-desc">
          根據110年教育部體育署針對台灣運動現況的調查結果顯示：散步/走路/健走在運動項目中佔男性49.8%，女性60.8%，皆排序運動項目第一名，鼓勵健走運動落實於台灣368個鄉鎮，開發新的健走路線，提升民眾運動動機，並同時推動更多創新與數據利他價值應用。 
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動 </div>
        <div class="content-desc">
          本計畫協手永悅健康、陽光綠野一同辦理「健走點燈摘星計畫-看見台灣探索制霸368鄉鎮」。 
        </div>
        <br>
        <div class="content-desc">
          於民國111年11月30日至112年2月19日止，共完成累計16,988人次及34,644筆運動生理數據，其中包含起始時間、紀錄軌跡、上升/下降海拔高度等運動數據，完整數據留存原始資料於健行筆記APP中，並已與運動數據公益平台串接。  
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據公益模式</div>
        <div class="content-desc">
          永悅健康結合健行筆記APP收集到的紀錄軌跡數據，透過計算軌跡與重疊率，數據應用對於推廣城市健康發展政策具有啟發性，透過統計分析結果規劃都市綠地，提供給政府作為參考依據。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公益展望</div>
        <div class="content-desc">
          建立「Hiking Tracks Explorer」即時健行熱區圖臺，以數據監控臺灣各地在健行活動上的空間差異，未來發展獎勵機制，促使人們跨區進行組隊共同探索臺灣綠地環境，將可預期中南部地區民眾之運動習慣與記錄工具的使用率提升。蒐集之運動軌跡可加以探討運動場館與健行活動熱區間之效益影響，如公園或健身房的設置對於該區健行活動密度的推拉力影響，並追蹤本活動對民眾運動推力的研究。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11106',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/sample_6.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple6-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
